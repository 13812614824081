import React, { useRef, useEffect,useState } from "react";
import { useNavigate } from 'react-router-dom'
import Cookies from "universal-cookie";
import { axiosRequest } from "../../../api";
import { baseURL } from "../../../constant";

export default function Paypal() {
  const paypal = useRef();
  const navigate = useNavigate();
  const cookies = new Cookies();
  const [currencytext, setCurrencytext] = useState("");
  var currency = cookies.get("currency")
  if(currency ===1){
    setCurrencytext("AUD");
  }else if(currency ===2){
    setCurrencytext("GBP");
  }else if(currency ===3){
    setCurrencytext("USD");
  }else if(currency ===4){
    setCurrencytext("HKD");
  }else if(currency ===5){
    setCurrencytext("EUR");
  }else if(currency ===6){
    setCurrencytext("CAD");
  }
  useEffect(() => {
    window.paypal
      .Buttons({
        createOrder: (data, actions, err) => {
          return actions.order.create({
            intent: "CAPTURE",
            purchase_units: [ 
              {
                description: "Payment from Wordsru",
                amount: {
                  currency_code: currencytext,
                  value: parseInt(cookies.get("price")) +
                  parseInt(cookies.get("setting").regular_delivery_amount) +
                  parseInt(cookies.get("setting").tax_amount),
                },
              },
            ],
          });
        },
        onApprove: async (data, actions) => {
          const order = await actions.order.capture();
          console.log(order);

              
        const config = {
          headers: { "content-type": "multipart/form-data" },
        };
        const data1 = {
          currentStep: 5,
          card_number: '4444222222222222',
          exp_month: '11',
          exp_year: '2023',
          cvc: '123',
          currency: cookies.get("currency"),
          stripeToken: cookies.get("stripeToken"),
          stripeTokenID: order.id,
          email: cookies.get("userEmail"),
          password: "",
          name: cookies.get("userName"),
          file: cookies.get("filecookies"),
          country: "",
          wordlength: cookies.get("wordlength"),
          serviceType: cookies.get("serviceType"),
          jobType: "Basic Service",
          price: cookies.get("price"),
          deliverydate: cookies.get("deliverydate"),
          formtab: "",
          success: "false",
          loginSuccess: "false",
          loginFail: "false",
          ratio: cookies.get("ratio"),
          editorFees: cookies.get("editorFees"),
          userId: cookies.get("userId"),
          jobId: cookies.get("jobId"),
          fullAmountQuoted:
            parseInt(cookies.get("price")) +
            parseInt(cookies.get("setting").regular_delivery_amount) +
            parseInt(cookies.get("taxvalue")),
          isFileUploaded: "",
        };
        axiosRequest
          .post(`${baseURL}/submitQuotePageJobPayPal`, data1, config)
          .then((response) => {

          
          })
          .catch((error) => {
            console.log(error);
          });

          navigate("/thank-you?payment_intent="+order.id);
        },
        onError: (err) => {
          console.log(err);
        },
      })
      .render(paypal.current);
  }, []);

  return (
    <>
    <script src="https://www.paypal.com/sdk/js?client-id=ASf-1FjPx45SxHr0QI4iiRYjNijJGMr18MPo3uEvr4y6GqE64_mu1n7KyS0SeJb9lMc5TSkIUq7E78k5&currency={currencytext}"></script>
    <div className="paypalbutton" ref={paypal}>
      
    </div>
    </>
    
  );
}